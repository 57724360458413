import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/vercel/path0/middleware/authenticated.ts"),
  "check-reset-token": () => import("/vercel/path0/middleware/check-reset-token.ts"),
  checkout: () => import("/vercel/path0/middleware/checkout.ts")
}